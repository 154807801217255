const selectors = {
  videoExternal: "[data-video-external-target]",
  triggerButton: '.js-media-video-trigger'
}

class vimeoVideo extends HTMLElement {
  constructor () {
    super()
    this.initVideo()
  }

  async initVideo() {
    const vimeoJS = await import('https://player.vimeo.com/api/player.js')
    this.videoExternal = this.querySelector(selectors.videoExternal)
    this.btnTrigger = this.querySelector(selectors.triggerButton)

    if (this.videoExternal) {
      const {videoId, loop} = this.videoExternal.dataset
      let videoIdCurrent = videoId

      const player = new window.Vimeo.Player(this.videoExternal, {
        id: videoIdCurrent,
        controls: false,
        keyboard: false,
        autoplay: 1,
        muted: 1,
        loop: true
      })

      player.setAutopause(false)

      player.on('play', () => {
        this.btnTrigger.removeEventListener('click', handleVideoPlay)
        this.btnTrigger.addEventListener('click', handleVideoPause)
      })

      player.on('pause', () => {
        this.btnTrigger.removeEventListener('click', handleVideoPause)
        this.btnTrigger.addEventListener('click', handleVideoPlay)
      })

      const handleVideoPlay = () => {
        player.play().then(this.updateButtonState(false))
      }

      const handleVideoPause = () => {
        player.pause().then(this.updateButtonState(true))
      }
    }
  }

  updateButtonState(isPaused) {
    const state = isPaused ? 'paused' : 'playing'
    const label = isPaused ? 'Play' : 'Pause'

    this.btnTrigger.setAttribute('aria-label', `${label} video`)
    this.btnTrigger.setAttribute('data-video-status', state)
    this.btnTrigger.querySelector('.is-paused').hidden =
      state !== 'playing'
    this.btnTrigger.querySelector('.is-playing').hidden =
      state !== 'paused'
  }
}

if (!window.customElements.get('vimeo-video')) {
  window.customElements.define('vimeo-video', vimeoVideo)
}